import React from 'react';
import { BiCheck } from 'react-icons/bi';
import Button from '../../global/Button/Button';
import ImageWrapper from '../../global/ImageWrapper/ImageWrapper';
import * as S from './styles';

const offer = ({
	ticks,
	bgColor,
	color,
	wrapperBgColor,
	textColor,
	cta,
	ctaLink,
	features,
	title,
	subtitle,
	image,
}) => {
	return (
		<S.Wrapper wrapperBgColor={wrapperBgColor}>
			<S.InformationWrapper>
				<S.Title textColor={textColor}>{title}</S.Title>
				<S.Subtitle textColor={textColor}>{subtitle}</S.Subtitle>
				{ticks && (
					<S.TickWrapper>
						{features.map(feature => (
							<p>
								<BiCheck />
								<span>{feature.title}</span>
							</p>
						))}
					</S.TickWrapper>
				)}
				<S.ButtonWrapper>
					<Button btnColor={bgColor} color={color} to={`${ctaLink}`}>
						{cta}
					</Button>
				</S.ButtonWrapper>
			</S.InformationWrapper>
			<S.StyledImageWrapper>
				<ImageWrapper
					image={image}
					imageStyles={{
						borderRadius: '0px',
						borderTopLeftRadius: '7px',
						maxheight: '400px',
					}}
					otherStyles={{
						position: 'absolute',
						margin: '0 auto',
						maxWidth: '50%',
						width: '100%',
						textAlign: 'center',
						bottom: '0',
						right: '0',
					}}
				/>
			</S.StyledImageWrapper>
		</S.Wrapper>
	);
};

export default offer;
