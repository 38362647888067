import styled from 'styled-components';
import { setColors } from '../../../../utils/helpers';

export const ToggleWrapper = styled.label`
	display: inline-block;
	height: 34px;
	position: relative;
	width: 60px;
	& input {
		display: none;
	}

	.slider {
		background-color: ${setColors.themeBlue};
		bottom: 0;
		cursor: pointer;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: 0.4s;
	}

	.slider:before {
		background-color: ${setColors.white};
		bottom: 4px;
		content: '';
		height: 26px;
		left: 4px;
		position: absolute;
		transition: 0.4s;
		width: 26px;
	}

	input:checked + .slider {
		background-color: ${setColors.themeBlue};
	}

	input:checked + .slider:before {
		transform: translateX(26px);
	}

	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
`;
