import React from 'react';
import * as S from './styles';

export const Toggle = ({ value, onValueChange }, ...other) => {
	return (
		<S.ToggleWrapper for="checkbox" {...other}>
			<input
				type="checkbox"
				id="checkbox"
				value={value}
				checked={value}
				onChange={() => onValueChange()}
			/>
			<div className="slider round"></div>
		</S.ToggleWrapper>
	);
};
