import styled from 'styled-components';
import { mediaQuery } from '../../../../utils/helpers';

export const PlanCardsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	row-gap: 32px;
	width: 100%;
	margin-bottom: 23px;
	${mediaQuery.laptopML`
		grid-template-columns: 1fr 1fr;
	`}
	${mediaQuery.mobileXL`
        grid-template-columns: 1fr;
    `}
`;
