import styled, { css } from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

const baseParaStyle = css`
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.tiny};
	font-weight: ${setFontWeight.normal};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: -0.11px;
	text-align: center;
	${mediaQuery.laptopXL`font-size: ${setFontSize.mediumTiny}`};
	${mediaQuery.tabletL`font-size: ${setFontSize.veryTiny}`};
	${mediaQuery.mobileXL`line-height: 1.54;letter-spacing: -.08px;`};
`;

export const Wrapper = styled.section`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: ${props =>
		props.wrapperBgColor ? props.wrapperBgColor : setColors.lightBlack};
	margin-top: 100px;
	border-radius: 23px;
	justify-items: center;
	text-align: center;
	padding: 60px 0 0 60px;
	${mediaQuery.mobileXL`padding: 30px 15px;margin-top: 40px; flex-direction: column`};
`;

export const Title = styled.h3`
	font-family: ${setFontFamily.bold};
	font-size: ${setFontSize.mediumLarge};
	font-weight: ${setFontWeight.bold};
	${({ theme }) => theme.fontNormal};
	line-height: 1.09;
	max-width: 900px;
	text-align: left;
	color: ${props => (props.textColor ? props.textColor : setColors.white)};
	margin: 0;
	${mediaQuery.laptopXL`font-size: ${setFontSize.veryLarge}`};
	${mediaQuery.tabletL`font-size: ${setFontSize.mediumSmall}`};
	${mediaQuery.mobileXL`line-height: 1.38; text-align: center`};
`;

export const Subtitle = styled.p`
	${baseParaStyle};
	text-align: left;
	color: ${props => (props.textColor ? props.textColor : setColors.white)};
	max-width: 50%;
	${mediaQuery.mobileXL`margin: 20px 0; text-align: center; max-width: 80%`}
`;

export const TickWrapper = styled.div`
	max-width: 642px;
	width: 100%;
	padding: 15px 0 0 0;
	p {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 0px;
		margin: 0;
		margin-bottom: 10px;
		${baseParaStyle};
		color: ${setColors.white};
		line-height: 1;
		${mediaQuery.mobileXL`padding: 0;`};
		${mediaQuery.mobileXL`
			font-size: 16px
		`}
	}

	span {
		text-align: left;
	}

	svg {
		margin: 0;
		color: ${setColors.themeBlue};
		height: 25px;
		width: 25px;
		margin-right: 5px;
		${mediaQuery.tabletS`
			width: 22px;
			height: 22px
		`}
	}
	display: grid;
	grid-template-columns: 1fr;
	justify-items: flex-start;
	${mediaQuery.mobileXL`grid-template-columns: 1fr; min-width: 222px; margin: 15px auto; padding: 10px 0px 0 0px; width: auto`};
`;

export const InformationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	${mediaQuery.mobileXL`
		align-items: center;
	`}
`;

export const AmountWrapper = styled.div`
	color: ${setColors.white};
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	${mediaQuery.mobileXL`
		align-items: center;
		margin-top: 25px
	`}
	span.priceDollar,
	span.priceValue {
		font-family: ${setFontFamily.bold};
		font-size: 72px;
		font-weight: ${setFontWeight.bold};
		line-height: 91px;
		font-style: normal;
		${mediaQuery.laptopS`
			font-size: ${setFontSize.larg60};
		`}

		${mediaQuery.tabletXL`
			font-size: ${setFontSize.larger};
		`}

		${mediaQuery.tabletXL`
			font-size: ${setFontSize.large50};
		`}
	}
	span.priceTime {
		font-family: ${setFontFamily.bold};
		font-size: ${setFontSize.smaller};
		font-weight: ${setFontWeight.bold};
		line-height: 91px;
		font-style: normal;
	}
`;

export const PricingTitle = styled.h2`
	font-family: ${setFontFamily.bold};
	font-size: ${setFontSize.smaller};
	font-weight: ${setFontWeight.bold};
	line-height: 0px;
	font-style: normal;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0 70px 0;

	${mediaQuery.tabletL`
		margin-top: 20px;
	`}

	${mediaQuery.mobileXL`
		margin-top: 10px;
	`}
`;

export const StyledImageWrapper = styled.div`
	${mediaQuery.mobileXL`
		display: none;
	`}
`;
