import React from 'react';
import { PlanCard } from '../PlanCard/PlanCard';
import * as S from './styles';

const PlanCards = ({ planCards, showAnnualPlans }) => {
	return (
		<S.PlanCardsWrapper>
			{planCards.map(card => (
				<PlanCard
					card={card}
					key={card.planName}
					showAnnualPlans={showAnnualPlans}
				/>
			))}
		</S.PlanCardsWrapper>
	);
};

export const PlanCardsHolder = React.memo(PlanCards);
