import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const PlanCardWrapper = styled.div`
	padding: 32px;
	border-radius: 10px;
	margin: 0 16px;
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.veryVeryTiny};
	font-weight: ${setFontWeight.normal};
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: normal;
	text-align: left;
	color: ${setColors.lightBlack};
	background-color: ${setColors.planCardBackground};
	position: relative;
	${mediaQuery.laptopXL`
		padding: 20px;
	`}
	${mediaQuery.laptopML`
		margin-top: ${props => (props.isPopular ? '24px' : '0px')};
		padding: 32px;
	`}
`;

export const PopularBadge = styled.div`
	position: absolute;
	width: calc(100% + 2px);
	background-color: ${setColors.goldenOrange};
	color: ${setColors.white};
	top: -24px;
	left: -1px;
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.tiny};
	font-weight: ${setFontWeight.normal};
	font-stretch: normal;
	font-style: normal;
	line-height: 0.93;
	letter-spacing: normal;
	text-align: center;
	line-height: 32px;
	border-radius: 10px 10px 0 0;
`;

export const PlanName = styled.h2`
	margin: 0;
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.tiny};
	font-weight: ${setFontWeight.bold};
	font-stretch: normal;
	font-style: normal;
	line-height: 0.93;
	letter-spacing: normal;
	text-align: left;
	color: ${setColors.lightBlack};
	text-transform: uppercase;
`;

export const PlanDescription = styled.p`
	margin: 0;
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.veryVeryTiny};
	font-weight: ${setFontWeight.normal};
	font-stretch: normal;
	font-style: normal;
	line-height: 2.5ex;
	letter-spacing: normal;
	text-align: left;
	color: ${setColors.lightBlack};
	height: 8ex;
`;

export const Pricing = styled.div`
	display: flex;
	height: 80px;
	justify-content: flex-start;
	align-items: center;
	span {
		font-family: ${setFontFamily.bold};
		font-weight: ${setFontWeight.bold};
		font-stretch: normal;
		font-style: normal;
		line-height: 1;
		letter-spacing: normal;
		color: ${setColors.lightBlack};
	}
	span.customValue {
		font-size: ${setFontSize.smaller};
	}
	span.priceDollar {
		vertical-align: top;
		margin-right: 3px;
	}
	span.priceValue {
		font-size: ${setFontSize.littleLarge};
		align-self: center;
		line-height: 0.7;
	}
	span.priceDollar {
		font-size: ${setFontSize.littleLarge};
		line-height: 0.7;
	}
	span.priceTime {
		font-size: ${setFontSize.tiniest};
	}
`;

export const Features = styled.div`
	margin-bottom: ${props => (props.topList ? 0 : '32px')};
	p,
	ul,
	li {
		margin: 0;
		padding: 0;
		font-size: ${setFontSize.veryVeryTiny};
		font-family: ${setFontFamily.book};
		font-weight: ${setFontWeight.bold};
		line-height: 18px;
	}
	li {
		padding: ${props => (props.bottomList ? '7px 0' : '5px 0')};
		padding-left: ${props => (props.topList ? 0 : '20px')};
		line-height: 1;
		list-style: none;
		position: relative;
		font-size: ${setFontSize.veryTiny};
		font-weight: 450;
		line-height: 18px;
		font-style: normal;
		svg {
			color: ${setColors.themeBlue};
			font-size: ${setFontSize.verySmall};
			position: absolute;
			left: -3px;
			top: 6px;
		}
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	a {
		border-radius: 5px;
		width: 100%;
		font-family: ${setFontFamily.book};
		font-size: ${setFontSize.mediumTiny};
		font-weight: ${setFontWeight.bold};
		font-stretch: normal;
		font-style: normal;
		line-height: 0.5;
		letter-spacing: normal;
		color: ${setColors.white};
		text-transform: uppercase;
	}
`;

export const Divider = styled.hr`
	width: 100%;
	color: ${setColors.planCardGrey};
	margin: 20px 0;
`;
