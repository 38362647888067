import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../utils/helpers';

export const TitleWrapper = styled.div`
	font-weight: ${setFontWeight.bold} !important;
	.blue {
		color: ${setColors.themeBlue};
		text-decoration: underline;
	}
`;

export const ToggleWrapper = styled.div`
	width: 100%;
	margin-top: 100px;
	margin-bottom: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	div.toggleLabel {
		margin: 0 16px;
		position: relative;
	}
	${mediaQuery.laptopML`
		margin-bottom: 50px;
	`}
	${mediaQuery.mobileXL`
		margin-top: 32px;
		margin-bottom: 32px;
	`}
`;

export const DiscountWrapper = styled.div`
	position: absolute;
	left: calc(100% + 4px);
	top: -42px;
	width: 82px;
	transform: translateY(-50%);
	${mediaQuery.mobileXL`
		display: none;
	`}
`;

export const GotoDetails = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 60px;
	margin-bottom: 25px;
	h2 {
		margin: 0;
		font-family: ${setFontFamily.bold};
		font-size: ${setFontSize.smaller};
		font-weight: ${setFontWeight.bold};
		font-stretch: normal;
		font-style: normal;
		line-height: 30px;
		letter-spacing: normal;
		color: ${setColors.lightBlack};
		margin-right: 10px;
		${mediaQuery.mobileXXS`
			text-align: center
		`}
	}
	svg {
		width: 22px;
		height: 22px;
		cursor: pointer;
		color: ${setColors.themeBlue};
	}
`;

export const ArrowWrapper = styled.div`
	position: absolute;
	top: -23px;
	right: -32px;
	${mediaQuery.mobileXL`
		display: none;
	`}
`;
