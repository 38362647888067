import styled from 'styled-components';
import {
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
} from '../../../../utils/helpers';

export const Wrapper = styled.p`
	margin: 0 !important;
	background-color: ${setColors.green};
	border-radius: 3px;
	color: ${setColors.white};
	font-family: ${setFontFamily.book};
	font-size: ${setFontSize.mediumTiny} !important;
	font-weight: ${setFontWeight.bold};
	line-height: 1.38;
	${props => props.theme.fontNormal}
	text-align: center;
	padding: 3px 5px;

	${mediaQuery.tabletXL`
        font-size: ${setFontSize.tiniest} !important;
    `}
`;
