import React from 'react';
import { BiCheck } from 'react-icons/bi';
import { checkLink } from '../../../../utils/helpers';
import Button from '../../global/Button';
import * as S from './styles';

const generateButton = (url, text) => {
	const urlType = checkLink(url);
	if (urlType === 2) {
		return <Button to={`${url}`}>{text}</Button>;
	} else if (urlType === 1) {
		return (
			<Button as="a" href={url} target="_self">
				{text}
			</Button>
		);
	} else {
		return (
			<Button as="a" href={url} target="_blank" rel="noopener noreferrer">
				{text}
			</Button>
		);
	}
};

export const PlanCard = ({ card, showAnnualPlans }) => {
	return (
		<S.PlanCardWrapper isPopular={card.isPopular}>
			{card.isPopular ? <S.PopularBadge>Most Popular</S.PopularBadge> : null}
			<S.PlanName>{card.planName}</S.PlanName>
			<S.Pricing>
				{card.pricing.isCustom ? (
					<span className="customValue">
						{showAnnualPlans
							? card.pricing.priceAnnual
							: card.pricing.priceMonthly}
					</span>
				) : (
					<div className="priceWrapper">
						<span className="priceDollar">$</span>
						<span className="priceValue">
							{showAnnualPlans
								? card.pricing.priceAnnual
								: card.pricing.priceMonthly}
						</span>
						<span className="priceTime">
							{showAnnualPlans ? '/year' : '/month'}
						</span>
					</div>
				)}
			</S.Pricing>
			<S.ButtonWrapper>
				{generateButton(card.ctaLink, card.ctaText)}
			</S.ButtonWrapper>
			<S.Divider />
			<S.Features topList>
				<ul>
					{card.featureList.topList.map((item, i) => (
						<li key={i} dangerouslySetInnerHTML={{ __html: item }} />
					))}
				</ul>
			</S.Features>
			<S.Divider />
			<S.Features bottomList>
				<p dangerouslySetInnerHTML={{ __html: card.featureList.header }}></p>
				<ul>
					{card.featureList.list.map((item, i) => (
						<li key={i}>
							<BiCheck />
							{item}
						</li>
					))}
				</ul>
				<p dangerouslySetInnerHTML={{ __html: card.featureList.footer }}></p>
			</S.Features>
		</S.PlanCardWrapper>
	);
};
