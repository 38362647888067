import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/global/layout/Layout';
import { PlanCardsHolder } from '../components/pricing-v2/PlanCardsHolder/PlanCardsHolder';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import { Container } from '../components/global/customers/styles';
import { Title } from '../components/bot-builder-page/styles';
import {
	TitleWrapper,
	ToggleWrapper,
	DiscountWrapper,
	GotoDetails,
	ArrowWrapper,
} from '../components/pricing-v2/styles';
import { Toggle } from '../components/pricing-v2/Toggle/Toggle';
import Offer from '../components/pricing-page/offer/Offer';
import { mediaQuery, setColors } from '../../utils/helpers';
import Faq from '../components/global/Faq/Faq';
import SEO from '../components/global/seo';
import Badge from '../components/global/Badge/Badge';
import { CONSTANTS } from '../../utils/constants';
import NewTabIcon from '../svg/compare-plans.svg';

const FAQWrapper = styled.div`
	background: ${setColors.warmGray};
	padding: 140px 0 90px 0;
	margin: 100px 36px 0px;
	${mediaQuery.mobileXL`
		padding: 35px 0 70px 0;
		margin: 80px 18px 0px;
	`}
`;

const Pricing = ({ data }) => {
	const [isAnnualChecked, setAnnualChecked] = useState(false);
	const {
		section1Title,
		annualSavings,
		planCards,
		gotToPlanDetailsText,
		comparePlansPdfUrl,
		enterpriseSectionTitle,
		enterpriseSectionFeatureList,
		enterpriseSectionCtaLink,
		enterpriseSectionCta,
		enterpriseSectionSubtitle,
		enterpriseSectionImage,
		faqTitle,
		faqSubtitle,
		faqQuestions,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
	} = data.allContentfulPricingV2.nodes[0];

	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				seoJsonSchema={seoJsonSchema}
			/>
			<Container>
				<TitleWrapper>
					<Title
						className="font-black-54"
						dangerouslySetInnerHTML={{ __html: section1Title }}
					></Title>
					<ToggleWrapper>
						<div className="toggleLabel">Monthly</div>
						<Toggle
							value={isAnnualChecked}
							onValueChange={() => {
								setAnnualChecked(prev => !prev);
							}}
						/>
						<div className="toggleLabel">
							Annually
							<ArrowWrapper>{CONSTANTS.PRICING_PAGE_ARROW}</ArrowWrapper>
							<DiscountWrapper>
								<Badge content={`Save ${annualSavings}%`} />
							</DiscountWrapper>
						</div>
					</ToggleWrapper>
				</TitleWrapper>
			</Container>
			<WrapperOne>
				<PlanCardsHolder
					planCards={planCards}
					showAnnualPlans={isAnnualChecked}
				/>
				<GotoDetails>
					<h2>{gotToPlanDetailsText}</h2>
					<a href={comparePlansPdfUrl} target="_blank" rel="noreferrer">
						<NewTabIcon />
					</a>
				</GotoDetails>
			</WrapperOne>
			<WrapperOne>
				<Offer
					ticks="yes"
					bgColor={setColors.themeBlue}
					color={setColors.white}
					cta={enterpriseSectionCta}
					ctaLink={enterpriseSectionCtaLink}
					features={enterpriseSectionFeatureList}
					title={enterpriseSectionTitle}
					showAnnualPricing={isAnnualChecked}
					subtitle={enterpriseSectionSubtitle}
					image={enterpriseSectionImage}
				/>
			</WrapperOne>
			<FAQWrapper>
				<Container>
					<Faq title={faqTitle} subtitle={faqSubtitle} content={faqQuestions} />
				</Container>
			</FAQWrapper>
		</Layout>
	);
};

export const query = graphql`
	{
		allContentfulPricingV2 {
			nodes {
				section1Title
				annualSavings
				planCards {
					pricing {
						isCustom
						priceAnnual
						priceMonthly
					}
					planName
					isPopular
					ctaText
					ctaLink
					featureList {
						topList
						list
						header
						footer
					}
				}
				gotToPlanDetailsText
				comparePlansPdfUrl
				enterpriseSectionTitle
				enterpriseSectionFeatureList {
					title
				}
				enterpriseSectionCtaLink
				enterpriseSectionCta
				enterpriseSectionSubtitle
				enterpriseSectionImage {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				faqTitle
				faqSubtitle {
					raw
				}
				faqQuestions {
					question
					answer {
						type
						content
					}
				}
				seoTitle
				seoDescription
				seoArticle
				seoImage {
					title
					file {
						url
						details {
							image {
								height
								width
							}
						}
					}
				}
				seoJsonSchema {
					internal {
						content
					}
				}
			}
		}
	}
`;

export default Pricing;
